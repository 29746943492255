import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, vShow as _vShow, withDirectives as _withDirectives, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-2b6337a4"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "align-items-center small fw-bolder text-black mx-2"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_BImg = _resolveComponent("BImg");
  const _component_BNavbarBrand = _resolveComponent("BNavbarBrand");
  const _component_BNavItem = _resolveComponent("BNavItem");
  const _component_BButton = _resolveComponent("BButton");
  const _component_router_link = _resolveComponent("router-link");
  const _component_BNavbarNav = _resolveComponent("BNavbarNav");
  const _component_BContainer = _resolveComponent("BContainer");
  const _component_BNavbar = _resolveComponent("BNavbar");
  return _openBlock(), _createElementBlock("header", null, [_createVNode(_component_BNavbar, {
    expand: "lg",
    class: "header"
  }, {
    default: _withCtx(() => [_createVNode(_component_BContainer, {
      fluid: "",
      class: "d-flex justify-content-between align-items-center"
    }, {
      default: _withCtx(() => [_createVNode(_component_BNavbarBrand, {
        href: "/",
        class: "d-flex align-items-center px-2 mx-1"
      }, {
        default: _withCtx(() => [_createVNode(_component_BImg, {
          src: $data.logo,
          alt: "Logo",
          height: "28"
        }, null, 8, ["src"])]),
        _: 1
      }), _createVNode(_component_BNavbarNav, {
        class: "ml-auto d-flex align-items-center"
      }, {
        default: _withCtx(() => [_withDirectives(_createVNode(_component_BNavItem, null, {
          default: _withCtx(() => [_createElementVNode("div", _hoisted_1, _toDisplayString($options.name), 1)]),
          _: 1
        }, 512), [[_vShow, $options.isAuthenticated]]), _withDirectives(_createVNode(_component_BNavItem, null, {
          default: _withCtx(() => [_createVNode(_component_BButton, {
            class: "btn-logout",
            variant: "outline-primary",
            onClick: $options.logout
          }, {
            default: _withCtx(() => [_createTextVNode("로그아웃 ")]),
            _: 1
          }, 8, ["onClick"])]),
          _: 1
        }, 512), [[_vShow, $options.isAuthenticated]]), _withDirectives(_createVNode(_component_BNavItem, null, {
          default: _withCtx(() => [_createVNode(_component_router_link, {
            to: "/login",
            class: "btn btn-primary btn-login"
          }, {
            default: _withCtx(() => [_createTextVNode("로그인 ")]),
            _: 1
          })]),
          _: 1
        }, 512), [[_vShow, !$options.isAuthenticated]])]),
        _: 1
      })]),
      _: 1
    })]),
    _: 1
  })]);
}