import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeStyle as _normalizeStyle, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = {
  class: "position-sticky fixed-top"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_AppHeader = _resolveComponent("AppHeader");
  const _component_AppSidebar = _resolveComponent("AppSidebar");
  const _component_router_view = _resolveComponent("router-view");
  return _openBlock(), _createElementBlock(_Fragment, null, [_createElementVNode("div", _hoisted_1, [_createVNode(_component_AppHeader)]), $options.isAuthenticated ? (_openBlock(), _createBlock(_component_AppSidebar, {
    key: 0,
    "is-collapsed": $data.isSidebarCollapsed,
    onToggle: $options.toggleSidebar
  }, null, 8, ["is-collapsed", "onToggle"])) : _createCommentVNode("", true), _createElementVNode("div", {
    style: _normalizeStyle({
      marginLeft: $options.isAuthenticated ? $data.isSidebarCollapsed ? '80px' : '253px' : '0'
    })
  }, [_createVNode(_component_router_view)], 4)], 64);
}