import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createBlock as _createBlock, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-37f3ca80"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "sidebar-item-inactive"
};
const _hoisted_2 = {
  key: 0,
  class: "sidebar-item-name"
};
const _hoisted_3 = {
  class: "sidebar-item-active"
};
const _hoisted_4 = {
  key: 0,
  class: "sidebar-item-name"
};
const _hoisted_5 = {
  class: "sidebar-item-active"
};
const _hoisted_6 = {
  key: 0,
  class: "sidebar-item-name"
};
const _hoisted_7 = {
  class: "sidebar-item-active"
};
const _hoisted_8 = {
  key: 0,
  class: "sidebar-item-name"
};
const _hoisted_9 = {
  class: "sidebar-item-active"
};
const _hoisted_10 = {
  key: 0,
  class: "sidebar-item-name"
};
const _hoisted_11 = {
  class: "sidebar-item-active"
};
const _hoisted_12 = {
  key: 0,
  class: "sidebar-item-name"
};
const _hoisted_13 = {
  class: "sidebar-item-active"
};
const _hoisted_14 = {
  key: 0,
  class: "sidebar-item-name"
};
const _hoisted_15 = {
  class: "sidebar-item-active"
};
const _hoisted_16 = {
  key: 0,
  class: "sidebar-item-name"
};
const _hoisted_17 = {
  class: "sidebar-item-active"
};
const _hoisted_18 = {
  key: 0,
  class: "sidebar-item-name"
};
const _hoisted_19 = {
  class: "mt-auto text-start"
};
const _hoisted_20 = {
  key: 0,
  class: "sidebar-item-name"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon");
  const _component_BNavItem = _resolveComponent("BNavItem");
  const _component_router_link = _resolveComponent("router-link");
  const _component_BNav = _resolveComponent("BNav");
  return _openBlock(), _createElementBlock("div", null, [_createElementVNode("div", {
    class: _normalizeClass(['sidebar', {
      collapsed: $props.isCollapsed
    }])
  }, [_createElementVNode("div", null, [_createVNode(_component_BNav, {
    vertical: ""
  }, {
    default: _withCtx(() => [$options.isAdmin ? (_openBlock(), _createBlock(_component_router_link, {
      key: 0,
      to: "/",
      class: "text-decoration-none"
    }, {
      default: _withCtx(() => [_createVNode(_component_BNavItem, null, {
        default: _withCtx(() => [_createElementVNode("div", _hoisted_1, [_createElementVNode("div", null, [_createVNode(_component_font_awesome_icon, {
          icon: ['fas', 'chart-pie'],
          class: "sidebar-item-icon"
        })]), !$props.isCollapsed ? (_openBlock(), _createElementBlock("div", _hoisted_2, " 대시보드 ")) : _createCommentVNode("", true)])]),
        _: 1
      })]),
      _: 1
    })) : _createCommentVNode("", true), $options.isAdmin ? (_openBlock(), _createBlock(_component_router_link, {
      key: 1,
      to: {
        path: '/members',
        query: {
          date: '',
          role: '',
          status: '',
          keyword: '',
          pageNumber: 0,
          pageSize: 15
        }
      },
      class: "text-decoration-none"
    }, {
      default: _withCtx(() => [_createVNode(_component_BNavItem, null, {
        default: _withCtx(() => [_createElementVNode("div", _hoisted_3, [_createElementVNode("div", null, [_createVNode(_component_font_awesome_icon, {
          icon: ['far', 'circle-user'],
          class: "sidebar-item-icon"
        })]), !$props.isCollapsed ? (_openBlock(), _createElementBlock("div", _hoisted_4, " 회원관리 ")) : _createCommentVNode("", true)])]),
        _: 1
      })]),
      _: 1
    })) : _createCommentVNode("", true), $options.isTeacher ? (_openBlock(), _createBlock(_component_router_link, {
      key: 2,
      to: "/me",
      class: "text-decoration-none"
    }, {
      default: _withCtx(() => [_createVNode(_component_BNavItem, null, {
        default: _withCtx(() => [_createElementVNode("div", _hoisted_5, [_createElementVNode("div", null, [_createVNode(_component_font_awesome_icon, {
          icon: ['far', 'circle-user'],
          class: "sidebar-item-icon"
        })]), !$props.isCollapsed ? (_openBlock(), _createElementBlock("div", _hoisted_6, " 컨설턴트 정보 ")) : _createCommentVNode("", true)])]),
        _: 1
      })]),
      _: 1
    })) : _createCommentVNode("", true), $options.isAdmin ? (_openBlock(), _createBlock(_component_router_link, {
      key: 3,
      to: {
        path: '/teachers',
        query: {
          keyword: '',
          status: '',
          pageNumber: 0,
          pageSize: 10
        }
      },
      class: "text-decoration-none"
    }, {
      default: _withCtx(() => [_createVNode(_component_BNavItem, null, {
        default: _withCtx(() => [_createElementVNode("div", _hoisted_7, [_createVNode(_component_font_awesome_icon, {
          icon: ['fas', 'id-card-clip'],
          class: "sidebar-item-icon"
        }), !$props.isCollapsed ? (_openBlock(), _createElementBlock("div", _hoisted_8, " 컨설턴트 정보 ")) : _createCommentVNode("", true)])]),
        _: 1
      })]),
      _: 1
    })) : _createCommentVNode("", true), _createVNode(_component_router_link, {
      to: {
        path: '/schedules',
        query: {
          teacherId: '',
          scheduleDate: '',
          bookingStatus: '',
          pageNumber: 0,
          pageSize: 10
        }
      },
      class: "text-decoration-none"
    }, {
      default: _withCtx(() => [_createVNode(_component_BNavItem, null, {
        default: _withCtx(() => [_createElementVNode("div", _hoisted_9, [_createElementVNode("div", null, [_createVNode(_component_font_awesome_icon, {
          icon: ['far', 'calendar-plus'],
          class: "sidebar-item-icon"
        })]), !$props.isCollapsed ? (_openBlock(), _createElementBlock("div", _hoisted_10, " 컨설턴트 시간관리 ")) : _createCommentVNode("", true)])]),
        _: 1
      })]),
      _: 1
    }), _createVNode(_component_router_link, {
      to: {
        path: '/consultations',
        query: {
          teacherId: '',
          scheduleDate: '',
          recordStatus: '',
          actionType: '',
          pageNumber: 0,
          pageSize: 10
        }
      },
      class: "text-decoration-none"
    }, {
      default: _withCtx(() => [_createVNode(_component_BNavItem, null, {
        default: _withCtx(() => [_createElementVNode("div", _hoisted_11, [_createElementVNode("div", null, [_createVNode(_component_font_awesome_icon, {
          icon: ['fas', 'graduation-cap'],
          class: "sidebar-item-icon"
        })]), !$props.isCollapsed ? (_openBlock(), _createElementBlock("div", _hoisted_12, " 상담채널관리 ")) : _createCommentVNode("", true)])]),
        _: 1
      })]),
      _: 1
    }), _createVNode(_component_router_link, {
      to: {
        path: '/qna',
        query: {
          pageNumber: 0,
          pageSize: 10
        }
      },
      class: "text-decoration-none"
    }, {
      default: _withCtx(() => [_createVNode(_component_BNavItem, null, {
        default: _withCtx(() => [_createElementVNode("div", _hoisted_13, [_createElementVNode("div", null, [_createVNode(_component_font_awesome_icon, {
          icon: ['fas', 'headset'],
          class: "sidebar-item-icon"
        })]), !$props.isCollapsed ? (_openBlock(), _createElementBlock("div", _hoisted_14, " 질문/답변 관리 ")) : _createCommentVNode("", true)])]),
        _: 1
      })]),
      _: 1
    }), $options.isAdmin ? (_openBlock(), _createBlock(_component_router_link, {
      key: 4,
      to: {
        path: '/payments',
        query: {
          date: '',
          status: '',
          keyword: '',
          pageNumber: 0,
          pageSize: 15
        }
      },
      class: "text-decoration-none"
    }, {
      default: _withCtx(() => [_createVNode(_component_BNavItem, null, {
        default: _withCtx(() => [_createElementVNode("div", _hoisted_15, [_createElementVNode("div", null, [_createVNode(_component_font_awesome_icon, {
          icon: ['fas', 'landmark'],
          class: "sidebar-item-icon"
        })]), !$props.isCollapsed ? (_openBlock(), _createElementBlock("div", _hoisted_16, " 결제관리 ")) : _createCommentVNode("", true)])]),
        _: 1
      })]),
      _: 1
    })) : _createCommentVNode("", true), $options.isAdmin ? (_openBlock(), _createBlock(_component_router_link, {
      key: 5,
      to: {
        path: '/messages',
        query: {
          date: '',
          status: '',
          keyword: '',
          pageNumber: 0,
          pageSize: 10
        }
      },
      class: "text-decoration-none"
    }, {
      default: _withCtx(() => [_createVNode(_component_BNavItem, null, {
        default: _withCtx(() => [_createElementVNode("div", _hoisted_17, [_createElementVNode("div", null, [_createVNode(_component_font_awesome_icon, {
          icon: ['fas', 'comment-sms'],
          class: "sidebar-item-icon"
        })]), !$props.isCollapsed ? (_openBlock(), _createElementBlock("div", _hoisted_18, "알림톡")) : _createCommentVNode("", true)])]),
        _: 1
      })]),
      _: 1
    })) : _createCommentVNode("", true)]),
    _: 1
  })]), _createElementVNode("div", _hoisted_19, [_createElementVNode("div", {
    onClick: _cache[0] || (_cache[0] = (...args) => $options.toggleSidebar && $options.toggleSidebar(...args)),
    class: "d-flex flex-row align-items-center btn text-black fw-light border-0 p-3"
  }, [_createElementVNode("div", null, [_createVNode(_component_font_awesome_icon, {
    icon: ['fas', $props.isCollapsed ? 'arrow-right-from-bracket' : 'angle-left'],
    class: "sidebar-item-icon"
  }, null, 8, ["icon"])]), !$props.isCollapsed ? (_openBlock(), _createElementBlock("div", _hoisted_20, "Collapsed")) : _createCommentVNode("", true)])])], 2)]);
}