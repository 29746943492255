import { createRouter, createWebHistory } from 'vue-router';
import store from '@/store';

const routes = [
  {
    path: '/',
    name: 'homePage',
    component: () => import('@/views/HomePage.vue'),
    meta: { requiresUser: true },
  },
  {
    path: '/about',
    name: 'aboutPage',
    component: () => import('@/views/AboutView.vue'),
    meta: { requiresUser: true },
  },
  {
    path: '/login',
    name: 'loginPage',
    component: () => import('@/views/LoginPage.vue'),
    meta: { requiresGuest: true },
  },

  {
    path: '/members',
    name: 'memberPage',
    component: () => import('@/views/MemberPage.vue'),
    meta: { requiresAdmin: true },
    children: [
      {
        path: '',
        name: 'members',
        component: () => import('@/components/member/MemberList.vue'),
        meta: { requiresAdmin: true },
      },
      {
        path: ':memberId',
        name: 'memberDetails',
        component: () => import('@/components/member/MemberDetails.vue'),
        meta: { requiresAdmin: true },
      },
    ],
  },

  {
    path: '/me',
    name: 'mePage',
    component: () => import('@/views/MePage.vue'),
    meta: { requiresUser: true },
    children: [
      {
        path: '',
        name: 'me',
        component: () => import('@/components/me/MeDetails.vue'),
        meta: { requiresUser: true },
      },
      {
        path: 'products/new',
        name: 'meProductCreation',
        component: () => import('@/components/me/MeProductCreationForm.vue'),
        meta: { requiresUser: true },
      },
      {
        path: 'products/:teacherProductId',
        name: 'meProductUpdate',
        component: () => import('@/components/me/MeProductUpdateForm.vue'),
        meta: { requiresUser: true },
      },
    ],
  },

  {
    path: '/teachers',
    name: 'teacherPage',
    component: () => import('@/views/TeacherPage.vue'),
    meta: { requiresAdmin: true },
    children: [
      {
        path: '',
        name: 'teachers',
        component: () => import('@/components/teacher/TeacherList.vue'),
        meta: { requiresAdmin: true },
      },
      {
        path: 'new',
        name: 'teacherCreation',
        component: () => import('@/components/teacher/TeacherCreationForm.vue'),
        meta: { requiresAdmin: true },
      },
      {
        path: ':teacherId',
        name: 'teacherDetails',
        component: () => import('@/components/teacher/TeacherDetails.vue'),
        meta: { requiresAdmin: true },
      },
      {
        path: ':teacherId/products/new',
        name: 'teacherProductCreation',
        component: () =>
          import('@/components/teacher/TeacherProductCreationForm.vue'),
        meta: { requiresAdmin: true },
      },
      {
        path: ':teacherId/products/:teacherProductId',
        name: 'teacherProductUpdate',
        component: () =>
          import('@/components/teacher/TeacherProductUpdateForm.vue'),
        meta: { requiresAdmin: true },
      },
    ],
  },

  {
    path: '/schedules',
    name: 'schedulePage',
    component: () => import('@/views/SchedulePage.vue'),
    meta: { requiresUser: true },
    children: [
      {
        path: '',
        name: 'schedules',
        component: () => import('@/components/schedule/ScheduleList.vue'),
        meta: { requiresUser: true },
      },
      {
        path: 'new',
        name: 'scheduleCreation',
        component: () => import('@/views/ScheduleCreationPage.vue'),
        meta: { requiresUser: true },
      },
    ],
  },

  {
    path: '/consultations',
    name: 'consultationPage',
    component: () => import('@/views/ConsultationPage.vue'),
    meta: { requiresUser: true },
    children: [
      {
        path: '',
        name: 'consultations',
        component: () =>
          import('@/components/consultation/ConsultationList.vue'),
        meta: { requiresUser: true },
      },
      {
        path: ':payRecordId/details',
        name: 'consultationDetails',
        component: () =>
          import('@/components/consultation/ConsultationDetails.vue'),
        meta: { requiresUser: true },
      },
    ],
  },

  {
    path: '/qna',
    name: 'qnaPage',
    component: () => import('@/views/QnaPage.vue'),
    meta: { requiresUser: true },
    children: [
      {
        path: '',
        name: 'qna',
        component: () => import('@/components/qna/QnaList.vue'),
        meta: { requiresUser: true },
      },
    ],
  },

  {
    path: '/payments',
    name: 'paymentPage',
    component: () => import('@/views/PaymentPage.vue'),
    meta: { requiresAdmin: true },
    children: [
      {
        path: '',
        name: 'payments',
        component: () => import('@/components/payment/PaymentList.vue'),
        meta: { requiresAdmin: true },
      },
      {
        path: ':payMasterId/details',
        name: 'paymentsDetails',
        component: () => import('@/components/payment/PaymentDetails.vue'),
        meta: { requiresAdmin: true },
      },
      {
        path: 'new',
        name: 'paymentsCreation',
        component: () => import('@/components/payment/PaymentCreationForm.vue'),
        meta: { requiresAdmin: true },
      },
    ],
  },

  {
    path: '/messages',
    name: 'messagePage',
    component: () => import('@/views/MessagePage.vue'),
    meta: { requiresAdmin: true },
    children: [
      {
        path: '',
        name: 'messageList',
        component: () => import('@/components/message/MessageList.vue'),
        meta: { requiresAdmin: true },
      },
      {
        path: 'new',
        name: 'messageForm',
        component: () => import('@/components/message/MessageForm.vue'),
        meta: { requiresAdmin: true },
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  const isAuthenticated = store.getters.isAuthenticated;
  const isAdmin = store.getters.isAdmin;
  document.title = '결정적 이순간 | 컨설턴트 플랫폼';

  if (to.matched.some(record => record.meta.requiresGuest) && isAuthenticated) {
    alert('이미 로그인된 상태입니다.');
    next({ path: '/' });
    return;
  }

  if (to.matched.some(record => record.meta.requiresUser) && !isAuthenticated) {
    next({ path: '/login' });
    return;
  }

  if (to.matched.some(record => record.meta.requiresAdmin) && !isAdmin) {
    alert('관리자 권한이 필요합니다.');
    next({ path: '/' });
    return;
  }

  next();
});

export default router;
