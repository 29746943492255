import { createStore } from 'vuex';
import {
  getRoleFromCookie,
  getTokenFromCookie,
  getNameFromCookie,
} from '@/utils/cookies';

export default createStore({
  state: {
    token: getTokenFromCookie() || '',
    name: getNameFromCookie() || '',
    role: getRoleFromCookie() || '',
    interval: '',
  },

  getters: {
    isAuthenticated(state) {
      return state.token !== '' && state.role !== '';
    },
    isAdmin(state) {
      return state.role === 'ROLE_ADMIN' || state.role === 'ROLE_DEV';
    },
    isTeacher(state) {
      return state.role === 'ROLE_TEACHER';
    },
    getName(state) {
      return state.name;
    },
    getInterval(state) {
      return state.interval;
    },
  },

  mutations: {
    setToken(state, token) {
      state.token = token;
    },
    setName(state, name) {
      state.name = name;
    },
    setRole(state, role) {
      state.role = role;
    },
    clearUser(state) {
      state.token = '';
      state.name = '';
      state.role = '';
    },
    setInterval(state, interval) {
      state.interval = interval;
    },
  },

  actions: {
    logout({ commit }) {
      commit('clearUser');
    },
  },
  modules: {},
});
