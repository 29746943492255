function saveTokenToCookie(value) {
  document.cookie = `dream_token=${value}; SameSite=Lax; Secure`;
}

function saveNameToCookie(value) {
  document.cookie = `dream_name=${value}; SameSite=Lax; Secure`;
}

function saveRoleToCookie(value) {
  document.cookie = `dream_role=${value}; SameSite=Lax; Secure`;
}

function getTokenFromCookie() {
  return document.cookie.replace(
    /(?:^|.*;\s*)dream_token\s*=\s*([^;]*).*$|^.*$/,
    '$1',
  );
}

function getNameFromCookie() {
  return document.cookie.replace(
    /(?:^|.*;\s*)dream_name\s*=\s*([^;]*).*$|^.*$/,
    '$1',
  );
}

function getRoleFromCookie() {
  return document.cookie.replace(
    /(?:^|.*;\s*)dream_role\s*=\s*([^;]*).*$|^.*$/,
    '$1',
  );
}

function deleteCookie(value) {
  document.cookie = `${value}=; expires=Thu, 01 Jan 1970 00:00:01 GMT;`;
}

function deleteCookies() {
  document.cookie =
    'dream_token' + `=; expires=Thu, 01 Jan 1970 00:00:01 GMT; path=/`;
  document.cookie =
    'dream_name' + `=; expires=Thu, 01 Jan 1970 00:00:01 GMT; path=/`;
  document.cookie =
    'dream_role' + `=; expires=Thu, 01 Jan 1970 00:00:01 GMT; path=/`;
}

export {
  saveTokenToCookie,
  saveNameToCookie,
  saveRoleToCookie,
  getTokenFromCookie,
  getNameFromCookie,
  getRoleFromCookie,
  deleteCookie,
  deleteCookies,
};
