import { deleteCookies } from '@/utils/cookies';
export default {
  data() {
    return {
      logo: require('@/assets/logo.png')
    };
  },
  computed: {
    name() {
      return this.$store.getters.getName;
    },
    isAuthenticated() {
      return this.$store.getters.isAuthenticated;
    }
  },
  methods: {
    async logout() {
      deleteCookies();
      await this.$store.dispatch('logout');
      window.location.href = '/login';
    }
  }
};