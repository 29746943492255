export default {
  props: {
    isCollapsed: {
      type: Boolean,
      required: true
    }
  },
  computed: {
    isAdmin() {
      return this.$store.getters.isAdmin;
    },
    isTeacher() {
      return this.$store.getters.isTeacher;
    }
  },
  methods: {
    toggleSidebar() {
      this.$emit('toggle');
    }
  }
};